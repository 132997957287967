import { resolveComponent as _resolveComponent, normalizeStyle as _normalizeStyle, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_badge = _resolveComponent("ion-badge")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_ion_button = _resolveComponent("ion-button")!

  return (_openBlock(), _createBlock(_component_ion_button, {
    fill: "clear",
    class: "py-0 px-0"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_router_link, { to: "/cart" }, {
        default: _withCtx(() => [
          _createVNode(_component_ion_icon, {
            ios: _ctx.cart,
            md: _ctx.cart,
            size: _ctx.iconSize,
            color: _ctx.iconColor,
            style: _normalizeStyle(_ctx.iconStyle)
          }, null, 8, ["ios", "md", "size", "color", "style"]),
          (_ctx.cartCount > 0)
            ? (_openBlock(), _createBlock(_component_ion_badge, {
                key: 0,
                id: "cart-badge",
                color: "primary",
                mode: "ios",
                style: {"margin-right":"2px"},
                class: "px-8"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.cartCount), 1)
                ]),
                _: 1
              }))
            : _createCommentVNode("", true)
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}