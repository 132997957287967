const expiresName = (name: string) => `${name}-expires`;
export enum localStorageEnum {
    cart = 'CART',
    coupons = 'COUPONS',
    orderTip = 'ORDER_TIP',
    orderDetail = 'ORDER_DETAIL',
    orderLogistics = 'ORDER_LOGISTICS',
    confirmation = 'CONFIRMATION',
    // Data for Dynatrace
    grandTotal = 'GRAND_TOTAL',
    orderType = 'ORDER_TYPE',
    itemsInCart = 'ITEMS_IN_CART',
    storeNumber = 'STORE_NUMBER',
    customerName = 'CUSTOMER_NAME',
    customerPhone = 'CUSTOMER_PHONE',
    loginRedirectUrl = 'LOGIN_REDIRECT_URL',
}

export function deleteLocalStorage(name: string) {
    window.localStorage.removeItem(name);
    window.localStorage.removeItem(expiresName(name));
}

export function getLocalStorage(name: string) {
    const expires = window.localStorage.getItem(expiresName(name));
    if (expires && +expires < new Date().getTime()) {
        deleteLocalStorage(name);
    }
    try {
        //@ts-ignore
        return JSON.parse(window.localStorage.getItem(name));
    } catch (error) {
        return window.localStorage.getItem(name);
    }
}
export function setLocalStorage(name: string, value: any, expirationDays?: number) {
    window.localStorage.setItem(name, typeof value == 'object' ? JSON.stringify(value) : value);
    if (expirationDays) {
        const now = new Date();
        const expires = expirationDays < 1 ? new Date().setHours(now.getHours() + expirationDays * 10) : new Date().setDate(now.getDate() + expirationDays);
        window.localStorage.setItem(expiresName(name), expires.toString());
    }
}
export function replaceLocalStorage(name: string, value: any, expirationDays?: number) {
    deleteLocalStorage(name);
    setLocalStorage(name, value, expirationDays);
}
