<template>
    <ion-select label-placement="floating" fill="outline" v-model="localModelValue" interface="action-sheet" :disabled="disabled" mode="md">
        <div slot="label" class="label-color">{{ label }} <ion-text v-if="required" color="danger">*</ion-text></div>
        <ion-select-option v-for="location in locations" :key="location.name" :value="location.id">
            {{ location.name }}
        </ion-select-option>
    </ion-select>
</template>

<script lang="ts">
import { defineComponent, computed, watch, PropType } from 'vue';
import { IonSelect, IonSelectOption, IonText } from '@ionic/vue';
import { StoreLocation } from '@/models/locationModels';
export default defineComponent({
    props: {
        label: String,
        modelValue: {
            type: String,
            default: '',
            required: true,
        },
        locations: { type: Array as PropType<StoreLocation[]>, default: () => [] },
        disabled: { type: Boolean, default: false },
        required: Boolean,
        dataCy: { type: String, default: 'location-select' },
    },
    emits: ['update:modelValue'],
    setup(props, { emit }) {
        const localModelValue = computed({
            get: () => props.modelValue,
            set: (newValue) => emit('update:modelValue', newValue),
        });

        // Watch for changes
        watch(
            () => props.locations,
            (newLocations, oldLocations) => {
                if (newLocations.length > 0) {
                    localModelValue.value = newLocations[0].id;
                }
            }
        );

        return { localModelValue };
    },

    components: { IonSelect, IonSelectOption, IonText },
});
</script>
